<template>
<div>
  <mts-table
    v-model="pageItems" :pager="{ 'current-page': current, 'page-size':size, total }"
    :loading.sync="dataListLoading" :config="tableConfig"
    @pager:size-change="sizeChanged"
    @pager:current-change="pageChanged">
    <template #tableHeader>
      <el-button type="primary" @click="addDialogVisible=true">新增用户</el-button>
    </template>
    <template #status="{ row }">
      <el-switch :value="row.status" :active-value="1" :inactive-value="0" @change="(status) => updateItemStatus(row, { status })"></el-switch>
    </template>
    <template #tableActions="{ row }">
      <el-button type="text" :disabled="row.locked !== 1" @click.stop="unlockHandle(row.id)">解锁</el-button>
      <el-button type="text" @click.stop="showPwdWin(row)">密码重置</el-button>
      <el-button type="text" @click.stop="updateHandle(row.id)">编辑</el-button>
      <el-button type="text" @click.stop="deleteHandle(row.id)">删除</el-button>
    </template>
  </mts-table>

  <el-dialog
    title="密码重置"
    :visible="rePwdVisible"
    @close="rePwdVisible = false"
    width="300"
  >
    <mts-form v-model="rePwdForm" :config="pwdConfig" ref="dialogPwdForm"></mts-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="rePwdVisible = false">取消</el-button>
      <el-button type="primary" @click="submit()">确认</el-button>
    </span>
  </el-dialog>

  <el-dialog :visible.sync="addDialogVisible" title="新增">
    <add v-if="addDialogVisible" @close="addClose"></add>
  </el-dialog>

  <el-dialog :visible.sync="updateDialogVisible" title="修改">
    <update v-if="updateDialogVisible" :id="updateId" @close="updateClose"></update>
  </el-dialog>
</div>
</template>
<script>
import { tableItems, pwdItems } from './user.config'
import { mapState, mapActions } from 'vuex'
import mtsTable from '@/components/mts/table'
import mtsForm from '@/components/mts/form'
import _ from 'lodash'
import add from './add'
import update from './update'


export default {
  data () {
    return {
      tableConfig: {
        tableProps: {
          border: true
        },
        items: tableItems
      },
      dataListLoading: false,
      rePwdVisible: false,
      rePwdForm: {},
      addDialogVisible: false,
      updateDialogVisible: false,
      updateId: null
    }
  },
  components: {
    mtsTable, mtsForm,add,update
  },
  activated () {
    this.getDataList()
  },
  computed: {
    ...mapState('user', ['current', 'size', 'total', 'pageItems']),
    pwdConfig () {
      return {
        items: pwdItems,
        props: {
          labelWidth: '120px'
        }
      }
    }
  },
  methods: {
    ...mapActions('user', [ 'page', 'delete', 'unlock', 'save', 'updateStatus', 'resetPassword' ]),
    sizeChanged (size) {
      this.getDataList(this.current, size)
    },
    pageChanged (page) {
      this.getDataList(page)
    },
    getDataList (p, s) {
      if (!p) p = this.current
      if (!s) s = this.size
      this.dataListLoading = true
      return this.page({ page: p, limit: s }).then(() => {
        this.dataListLoading = false
      }).catch((e) => {
        this.dataListLoading = false
        this.$message.error(e.message)
      })
    },
    unlockHandle (id) {
      this.$confirm('确认要解锁这个用户？', '解锁用户', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return this.unlock(id).then(() => {
          this.getDataList()
          this.$message.success('解锁用户成功')
        }).catch((e) => {
          console.error(e)
          this.$message.error(e.message)
        })
      })
    },
    updateHandle (id) {
      console.log(id)
      this.updateId = id
      this.updateDialogVisible = true
    },
    deleteHandle (ids) {
      if (!ids) {
        this.$message.warning('请选择需要删除的项目')
        return
      }
      if (!Array.isArray(ids)) ids = [ ids ]
      this.$confirm('确认要删除这个项目？', '删除用户', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return this.delete(ids).then(() => {
          this.getDataList()
          this.$message.success('删除用户成功')
        }).catch((e) => {
          console.error(e)
          this.$message.error(e.message)
        })
      })
    },
    updateItemStatus (row, data) {
      this.$confirm('确认修改此项的状态？', '修改用户状态', {
        confirmButtonText: '提交',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let form = _.cloneDeep(data)
        form.id = row.id
        this.updateStatus(form).then(() => {
          this.getDataList()
          this.$message.success('修改用户状态成功')
        }).catch((e) => {
          console.error(e)
          this.$message.error(e.message)
        })
      })
    },
    showPwdWin (row) {
      let rePwdForm = {}
      if (row && row.id) {
        rePwdForm.id = row.id
      } else return
      this.$set(this, 'rePwdForm', rePwdForm)
      this.rePwdVisible = true
      // console.log(row)
    },
    submit () {
      let rePwdForm = _.cloneDeep(this.rePwdForm)
      if (rePwdForm.password !== rePwdForm.rePwd) {
        this.$alert('两次输入的密码不一致', '密码重置', { type: 'error' })
        return
      }
      delete rePwdForm.rePwd
      this.$refs.dialogPwdForm.validate().then(() => this.resetPassword(rePwdForm)).then(() => {
        this.$message.success('密码重置成功')
        this.getDataList(1)
        this.rePwdVisible = false
      }).catch((e) => {
        this.$alert(e.message, '密码重置', { type: 'error' })
      })
    },
    /**
     * 新增功能关闭事件
     */
    addClose() {
      this.addDialogVisible = false
      this.getDataList()
    },
    /**
     * 修改功能关闭事件
     */
    updateClose() {
      this.updateDialogVisible = false
      this.getDataList()
    }
  }
}
</script>
